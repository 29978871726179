@font-face {
    font-family: ApercuPro-Regular-Web;
    src: url(./fonts/ApercuPro-Regular-Web.eot);
    src: url(./fonts/ApercuPro-Regular-Web.eot?#iefix) format("embedded-opentype"),
        url(./fonts/ApercuPro-Regular-Web.woff) format("woff"),
        url(./fonts/ApercuPro-Regular-Web.woff2) format("woff2"),
        url(./fonts/ApercuPro-Regular-Web.ttf) format("truetype"),
        url(./fonts/ApercuPro-Regular-Web.svg#ApercuPro-Regular-Web) format("svg");
    font-weight: 400;
    font-style: normal;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}


body {
    position: relative;
    font-family: Arial, Helvetica, sans-serif;
    transition: .5s;
    background-color: #f2f2f2;
}

.sidebar::-webkit-scrollbar {
    width: 5px;
    height: 10px;
}

.sidebar::-webkit-scrollbar-track {
    background: #333;
}

.sidebar::-webkit-scrollbar-thumb {
    background: #333;
}

.sidebar::-webkit-scrollbar-thumb:hover {
    background: #f2f2f2;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: ApercuPro-Regular-Web;
}

.text-deco {
    text-decoration: underline;
}

a {
    text-decoration: none;
    color: #005595;
}

img {
    max-width: 100%;
}

.bg-darkblue {
    background: #016c9f;
}

.bg-infolight {
    background: #7dc3f1;
}

.text-successlight {
    color: #41c363
}

.bg-successlight {
    background-color: #41c363;
}

.btn-outline-lightgreen {
    font-weight: bold;
    --bs-btn-color: #41c363;
    --bs-btn-border-color: #000;
    --bs-btn-hover-color: #333;
    --bs-btn-hover-bg: #41c363;
    --bs-btn-hover-border-color: #41c363;
    --bs-btn-focus-shadow-rgb: 13, 110, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41c363;
    --bs-btn-active-border-color: #41c363;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #41c363;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #41c363;
    --bs-gradient: none;
}

.btn-lightgreen {
    --bs-btn-color: #000;
    --bs-btn-bg: #41c363;
    --bs-btn-border-color: #41c363;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #198754;
    --bs-btn-hover-border-color: #198754;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #41c363;
    --bs-btn-active-border-color: #41c363;
    --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125);
}

.bg-light1 {
    background-color: #ddd;
}

.mat-column-action i {
    cursor: pointer;
}

.fa-pencil {
    font-size: 18px;
    margin-right: 10px;
    vertical-align: middle;
}

.fa-disabled {
    cursor: not-allowed;
    opacity: .5;
    /* pointer-events: none; */
}

.control-label {
    font-size: 14px;
    color: #000;
}

.form-control:focus {
    color: #212529;
    background-color: #fff;
    border-color: #f2f2f2;
    box-shadow: none;
    outline: 0;
}

.text-deco {
    text-decoration: underline !important;
}

.bg-light1 {
    background-color: #e3e3e5;
}

/* *************************************************** Header ************************************************* */

.mobile-user-icon,
.mobile-username {
    display: none;
}

/* *************************************************** End header ************************************************* */

/* *************************************************** Sidebar ************************************************* */
.side-nav {
    position: fixed;
    left: 0;
    min-height: 100vh;
    background: #1e252b;
    width: 310px;
    transition: 0.5s width;
    z-index: 9;
}

.close-menu .side-nav {
    width: 65px;
    transition: 0.5s width;
}

.top-logo {
    height: 85px;
    border-bottom: 1px solid #393e51;
    display: flex;
    align-items: center;
    padding: 15px 20px;
}

.close-menu .top-logo {
    padding: 15px 15px;
}

.top-logo a {
    width: 100%;
}

.top-logo a img {
    max-width: 180px;
}

.top-logo a img.small-logo {
    max-width: 36px;
    display: none;
}

.close-menu .top-logo a img.small-logo {
    display: block;
}

.close-menu .top-logo a img.large-logo {
    display: none;
}

.left-menu ul {
    padding: 0;
    list-style: none;
}

.left-menu ul li a {
    display: flex;
    align-items: center;
    padding: 20px 15px 20px 20px;
    border-bottom: 1px solid #3f4659;
    position: relative;
    transition: 0.5s all;
    line-height: 1;
}

.left-menu ul li a.active:after,
.left-menu ul li a:hover:after {
    content: '';
    position: absolute;
    left: 0;
    height: 100%;
    width: 4px;
    background: #41c363;
}

.left-menu ul li a.active,
.left-menu ul li a:hover {
    background-color: #393e51;
}

.left-menu ul li a img {
    max-width: 24px;
    min-width: 24px;
    max-height: 24px;
}

.left-menu ul li a.active img,
.left-menu ul li a:hover img {
    filter: invert(56%) sepia(16%) saturate(416%) hue-rotate(180deg) brightness(98%) contrast(91%);
}

.left-menu ul li a span {
    color: #fff;
    font-family: ApercuPro-Regular-Web;
    font-size: 18px;
    margin-left: 15px;
    white-space: nowrap;
    transition: 0.5s all;
}

.close-menu .left-menu ul li a span {
    display: none;
    transition: 0.5s all;
}

.close-menu .left-menu ul li a:hover span {
    position: absolute;
    left: 65px;
    background: #000;
    color: #fff;
    padding: 8px 12px;
    line-height: 1.3;
    font-size: 14px;
    border-radius: 4px;
    display: block;
    margin-left: 10px;
}

.close-menu .left-menu ul li a span:before {
    content: '';
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    border-right: 9px solid #000;
    position: absolute;
    left: -7px;
    top: 8px;
}

.left-menu ul li a:hover span,
.left-menu ul li a.active span {
    color: #838fa5;
}

.right-side-content {
    margin-left: 310px;
    transition: 0.5s all;
}

.no-login-page .right-side-content {
    margin-left: 0 !important;
}

.no-login-page .main-content {
    padding: 0 !important;
}

.close-menu .right-side-content {
    margin-left: 65px;
    transition: 0.5s all;
}

header {
    display: flex;
    justify-content: space-between;
    height: 80px;
    padding: 0 30px;
    align-items: center;
    background: #fff;
    position: sticky;
    z-index: 999;
    top: 0;
}

header .left-header a {
    width: 30px;
    display: inline-block;
}

.right-header .dropdown-toggle {
    text-decoration: none;
    color: #646464;
    text-align: left;
    padding: 0 30px 0 0;
    position: relative;
}

.right-header .dropdown-menu {
    min-width: 200px;
    border-radius: 0;
}

.right-header .dropdown-menu.show:before {
    content: '';
    border-top: 0;
    border-right: 6px solid transparent;
    border-bottom: 6px solid;
    border-left: 6px solid transparent;
    color: #ababab;
    position: absolute;
    top: -6px;
    right: 10px;
}

.right-header .dropdown-toggle .welcome {
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    text-decoration: none;
}

.right-header .dropdown-toggle .profile-username {
    font-size: 16px;
    font-weight: bold;
    color: #000;
    display: block;
}

.right-header .dropdown-toggle:after {
    border-top: 6px solid;
    border-right: 6px solid transparent;
    border-bottom: 0;
    border-left: 6px solid transparent;
    color: #000;
    position: absolute;
    right: 0;
    bottom: 10px;
}

footer {
    position: fixed;
    bottom: 0%;
    width: 100%;
    background: #f2f2f2;
    text-align: center;
}

.mobile-logo-white {
    display: none;
}

/* *************************************************** End Sidebar ************************************************* */

/* *************************************************** Content ************************************************* */


/* ********************************************** Medai Query ************************************************ */

.login-bg {
    background: #f1f2f4;
    min-height: calc(100vh - 41px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-bg .login-box .login-logo {
    text-align: center;
    padding: 10px 0 30px;
}

.login-bg .login-box .login-logo img {
    max-width: 190px;
}

.login-bg .login-box .login-form {
    background: #fff;
    max-width: 400px;
    padding: 15px 50px 30px;
    -webkit-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    -moz-box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.4);
    border-radius: 5px;
}
.login-bg .login-box .login-form.registration-form{
    max-width: 750px;
    padding-left: 30px;
    padding-right: 30px;
}

.login-bg .login-box .login-form h3 {
    text-align: center;
    font-size: 24px;
    font-weight: 700;
    color: #147f37;
    margin-bottom: 25px;
}

.otp-inputs {
    display: flex;
    justify-content: center;
    padding-top: 15px;
}

.otp-inputs .mdc-text-field--filled.mdc-text-field--no-label .mdc-text-field__input {
    text-align: center;
}

.otp-inputs .mat-mdc-form-field {
    width: 35px;
    flex: 0 0 35px;
    margin: 0 5px;
}

.form-group label {
    font-size: 13px;
    font-weight: bold;
    margin-bottom: 5px;
}

.form-group label .required {
    color: #DC3545;
}

.btn-avnet {
    background: #41c363;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 14px;
    padding: 5px 25px;
    border: 1px solid #41c363;
    transition: 0.5s all;
}

.btn-avnet:hover,
.btn-avnet:focus,
.btn-avnet:focus-visible {
    background-color: #349c4f !important;
    border: 1px solid #349c4f;
    transition: 0.5s all;
}
.btn-avnet-red{
    background: #cf4b4b;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 14px;
    padding: 5px 25px;
    border: 1px solid #cf4b4b;
    transition: 0.5s all;
    color: #fff;
    display: inline-block;
    text-align: center;
}
.btn-avnet-red:hover,
.btn-avnet-red:focus,
.btn-avnet-red:focus-visible{
    background: #b52e2e;
    background-color: #b52e2e !important;
    border: 1px solid #b52e2e;
    transition: 0.5s all;
    color: #fff !important;
}
.btn-avnet-cancel{
    background: #fff;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    border: 1px solid #72777d;
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 14px;
    padding: 5px 25px;
    transition: 0.5s all;
    color: #41c363;
    display: inline-block;
    transition: 0.5s all;
}
.btn-avnet-cancel:hover,
.btn-avnet-cancel:focus,
.btn-avnet-cancel:focus-visible{
    background: #F1F2F3;
    color: #000;
    transition: 0.5s all;
    border: 1px solid #72777d;
}

.btn-avnet-outline {
    background: #fff;
    font-weight: 600;
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase;
    border-radius: 0px;
    font-size: 14px;
    padding: 5px 25px;
    border: 1px solid #72777d;
    color: #41c363;
    transition: 0.5s all;
}

.btn-avnet-outline:hover,
.btn-avnet-outline:focus,
.btn-avnet-outline:focus-visible {
    background: #F1F2F3;
    color: #000;
    transition: 0.5s all;
    border: 1px solid #72777d;
}

/* .filter .btn-avnet-outline{
    min-width: 135px;
} */
.mat-mdc-dialog-container .mdc-dialog__surface {
    overflow: visible !important;
}

.login-bottom {
    text-align: center;
    display: block;
    padding: 15px 0 0;
    font-size: 14px;
}




.table>:not(caption)>*>* {
    padding: 0.5rem 0.5rem;
    background-color: var(--bs-table-bg) !important;
}

.table>thead {
    vertical-align: bottom;
    background: #ddd !important;
}

.form-group {
    margin-bottom: 15px;
}

.mdc-text-field {
    overflow: inherit !important;
    font-size: 15px !important;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: -17px !important;
    color: #000 !important;
    font-size: 15px !important;
    font-weight: 500;
}

.mat-mdc-form-field {
    width: 100%;
}

.mdc-text-field {
    padding: 0px !important;
}

.mdc-text-field--filled .mdc-floating-label {
    left: 0 !important;
}

.mdc-text-field--filled .mdc-floating-label--float-above {
    transform: translateY(-8px) !important;
    font-size: 15px !important;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
}
.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-icon-suffix .mat-icon{
    padding: 0;
    display: flex;
    align-items: center;
}
.mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent !important;
    border: 1px solid #72777d !important;
    border-radius: 5px;
}
.disabled-form .mdc-text-field--filled:not(.mdc-text-field--disabled){
    background: #f3f3f3 !important;
    opacity: 0.7;
    pointer-events: none;
    border: 1px solid #d5d5d5 !important;
}

.mdc-text-field--filled.mdc-text-field--focused:not(.mdc-text-field--disabled) {
    border: 1px solid #41c363 !important;
}

.mat-mdc-form-field-subscript-wrapper:before {
    content: none !important;
}

.mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input,
.mat-mdc-select {
    color: rgb(33 37 41) !important;
    padding: 0 10px !important;
    font-size: 13px;
}

.mdc-line-ripple::before {
    border-bottom-width: 0px !important;
}

.mdc-text-field--filled .mdc-line-ripple::after {
    opacity: 0 !important;
    border-bottom-color: var(--mdc-theme-primary, #c2185b);
}

.mdc-text-field .mdc-text-field__input {
    caret-color: #333;
}

.search-group .mat-mdc-form-field-icon-suffix {
    border-left: 1px solid #72777d;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0;
    height: 33px;

}

.search-group .mat-mdc-form-field-icon-suffix button {
    border: 0;
    background: transparent;
    min-width: 32px;
    cursor: pointer;
    line-height: 0;
}

.mat-mdc-form-field-focus-overlay {
    display: none;
}

.mat-mdc-select-arrow {
    background: url(../images/select-arrow.png) no-repeat center center !important;
    width: 13px !important;
    height: 8px !important;
}

.mat-mdc-select-arrow svg {
    display: none;
}

.mat-mdc-select-panel {
    border: 1px solid #72777d !important;
    padding: 0 !important;
}

.mat-mdc-select-panel .mat-mdc-option {
    min-height: 34px !important;
    font-size: 12px !important;
    padding: 0 10px;
}

.mat-mdc-select-panel .mat-mdc-option .mat-pseudo-checkbox {
    display: none;
}

.mat-mdc-option:hover:not(.mdc-list-item--disabled),
.mat-mdc-option:focus:not(.mdc-list-item--disabled),
.mat-mdc-option.mat-mdc-option-active,
.mat-mdc-option.mdc-list-item--selected:not(.mat-mdc-option-multiple):not(.mdc-list-item--disabled) {
    background: #f1f2f3;
}

.mat-primary .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text,
.mdc-list-item--selected .mdc-list-item__primary-text,
.mdc-list-item--activated .mdc-list-item__primary-text {
    color: #000 !important;
}

.mat-mdc-paginator-page-size .mat-mdc-paginator-page-size-label {
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
    margin-left: 30px;
}

/* .mat-mdc-text-field-wrapper .mdc-text-field__input {
    height: 45px !important;
}*/
/* .mat-mdc-text-field-wrapper .mat-mdc-form-field-infix {
    min-height: 45px !important;
}  */


.mat-mdc-form-field-infix {
    min-height: 30px;
}

#search-addon {
    position: absolute;
    right: 0;
    height: 44px;
}

.save-btn:disabled {
    background-color: grey;
    color: white;
}

.cancel-btn:hover {
    background: #DC3545;
    color: white;
    border: 0;
}

.cancel-btn:disabled {
    background-color: grey;
    color: white;
}

.search-icon {
    position: absolute;
    height: 48px;
    top: 14px;
    right: 27px;
}

.mat-mdc-header-row {
    white-space: nowrap;
}

.progress-bar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    color: var(--bs-progress-bar-color);
    text-align: center;
    white-space: nowrap;
    background-color: #41c363;
    transition: var(--bs-progress-bar-transition);
}

.mdc-text-field--disabled.mdc-text-field--filled {
    background-color: #dddddd;
    border-radius: 6px;

}

.mat-mdc-form-field-input-control:disabled {
    color: rgb(33 37 41);
}

.employee-detail-table .mat-mdc-table .mat-mdc-row:hover,
.mat-mdc-table .mat-mdc-footer-row:hover {
    background-color: #41c363;
}

.table .mdc-form-field {
    height: 18px;
}

.dropdown-menu a:hover {
    background-color: #41c363;
}

.audit-status-width {
    width: 197px;
}

.mat-mdc-checkbox.mat-accent .mdc-checkbox--selected~.mdc-checkbox__ripple {
    background: #ff408100;
}

.mat-mdc-checkbox .mdc-checkbox__ripple {
    background: #0000 !important;
}

.cursor-ponter {
    cursor: pointer;
}

.mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:disabled:not(:checked)+.mdc-radio__background .mdc-radio__outer-circle {
    border-color: darkgray !important;
    opacity: 0.4 !important;
}

.h-28 {
    height: 28px;
}

.h-61 {
    height: 61vh;
    overflow: auto;
}

.fixed-link {
    position: sticky;
    bottom: 0;
}

.p-paginator .p-paginator-current {
    color: #333;
    font-weight: bold;
}



/* .body-pd .sidebar ul {
    position: relative !important;
    background-color: transparent;
    left: 0;
    box-shadow: none;
}

.sidebar ul {
    position: absolute;
    left: 30%;
    box-shadow: 1px 4px 30px #dddd;
    background: #fff;
} */

.main-content {
    padding: 20px 20px 0;
}

.main-content .title-header {
    display: flex;
    justify-content: space-between;
    padding: 0px 0 15px;
    align-items: center;
}

.main-content .title-header h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.main-content .title-header a {
    color: #41c363;
    text-decoration: underline;
    text-align: right;
}

.main-page {
    background: #fff;
    border-radius: 6px;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.09);
    -moz-box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.09);
    box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.09);
    min-height: calc(100vh - 190px);
}

.filter ul {
    display: flex;
    align-items: center;
    list-style: none;
    margin-bottom: 0;
    margin-left: -15px;
    margin-right: -15px;
    padding: 0;
    flex-flow: wrap;
}

.filter ul li {
    padding-left: 15px;
    padding-right: 15px;
    flex: 0 0 240px;
    max-width: 240px;
}

.filter ul li.filter-action {
    flex: 0 0 auto;
    max-width: inherit;
}

.filter ul li .form-group p-dropdown {
    display: block;
    width: 100%;
}
.form-group .p-inputwrapper-focus .p-dropdown{
   border: 1px solid #41c363 !important;
}

.form-group .p-inputwrapper-focus .p-dropdown .p-dropdown-header {
    padding: 5px 7px;
    border: 1px solid #72777d !important;
    border-bottom: 0 !important;
}
.form-group .p-inputwrapper-focus .p-dropdown .p-dropdown-header .p-dropdown-filter{
    height: 34px;
    margin: 0;
}
.form-group p-dropdown .p-dropdown, .p-dropdown-panel .p-dropdown-header .p-dropdown-filter{
    width: 100%;
    border: 1px solid #72777d !important;
    border-radius: 4px;
    outline: none;
    box-shadow: none;
}
.disabled-form .form-group p-dropdown .p-dropdown{
    background: #f3f3f3;
    border: 1px solid #d5d5d5 !important;
}
.disabled-form .form-group p-dropdown .p-dropdown .p-dropdown-trigger{
    opacity: 0.3;
}
.form-group p-dropdown .p-dropdown.p-focus{
    border: 1px solid #41c363 !important;
}
.form-group  .p-dropdown-items{
    flex-flow: wrap;
    text-align: left;
    margin: 0;
}
.form-group  .p-dropdown-items .p-element{
    width: 100%;
}

.p-dropdown-panel .p-dropdown-header .p-dropdown-filter:focus {
    border: 1px solid #41c363 !important;
}
.form-group p-dropdown .p-dropdown-label{
    color: #6c757d;
    font-size: 14px;
    padding-top: 6px;
    padding-bottom: 6px;
}
.form-group p-dropdown .p-dropdown .p-dropdown-trigger{
    width: 30px;
}
.form-group p-dropdown .p-dropdown .p-dropdown-trigger .p-dropdown-trigger-icon {
    font-size: 14px;
    color: #000;
    font-weight: 600;
}
.form-group .p-dropdown .p-dropdown-label{
    font-size: 14px;
    text-align: left;
}
/* table css */
.table-responsive .mat-mdc-table .mdc-data-table__header-row {
    height: auto;
}

.table-responsive .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell {
    background: #e2e3e4 !important;
    border: 1px solid #b8bbbd;
    padding: 7px 12px;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: bold;
    text-transform: uppercase;
}
.mat-sort-header-arrow{
    opacity: 1 !important;
    background: url(../images/sorting-bg.png) no-repeat center center;
    transform: none !important;
}
[aria-sort="ascending"] .mat-sort-header-arrow{
    background: url(../images/accensing-sort.png) no-repeat center center;
}
[aria-sort="descending"] .mat-sort-header-arrow{
    background: url(../images/descending-sort.png) no-repeat center center;
}

.mat-sort-header-arrow *{
    display: none !important;
}
.table-responsive .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cell .mat-sort-header-container{
    justify-content: space-between;
    align-items: center;
}

.table-responsive .mat-mdc-table .mdc-data-table__row {
    height: 56px;
}

.table-responsive .mat-mdc-table .mdc-data-table__row .mdc-data-table__cell {
    border: 1px solid #b8bbbd;
    font-size: 14px;
    font-family: Arial, Helvetica, sans-serif;
    padding: 0px 12px;
    position: relative;
}
.table-responsive .notification-table.mat-mdc-table .mdc-data-table__row .mdc-data-table__cell{
     vertical-align: top;
     padding-top: 10px;
     padding-bottom: 10px;
}

.cdk-column-action {
    white-space: nowrap;
    width: 110px;
}

.cdk-column-action .fa {
    font-size: 18px;
    vertical-align: middle;
}

.cdk-column-action .fa-eye {
    color: #2b7cd1;
}
/* .cdk-column-action .fa-pencil{
    color: #41c363;
} */
.cdk-column-action .fa-history{
    color: #ffc100;
}

.cdk-column-action .fa-trash {
    color: #ef0720;
}

.table-responsive .mat-mdc-table .mdc-data-table__row.unread .mdc-data-table__cell {
    padding-bottom: 30px;
    font-weight: bold;
    padding-top: 10px;
}
.table-responsive .mat-mdc-table .mdc-data-table__row .mdc-data-table__cell h4{
    font-weight: normal;
}

.table-responsive .mat-mdc-table .mdc-data-table__row .mdc-data-table__cell .new-icon {
    background: #e2e3e5;
    display: flex;
    align-items: center;
    position: absolute;
    padding: 3px 7px;
    bottom: 0;
    right: 0;
    font-size: 13px;
    font-weight: 600;
}

.table-responsive .mat-mdc-table .mdc-data-table__row .mdc-data-table__cell .new-icon img {
    width: 10px;
    min-width: 10px;
    margin-right: 5px !important;
}

.table-responsive .mat-mdc-table .mdc-data-table__row .mdc-data-table__cell h4 {
    font-size: 18px;
    /* font-weight: bold; */
}
.table-responsive .mat-mdc-table .mdc-data-table__row.unread .mdc-data-table__cell h4{
    font-weight: bold;
}

.table-responsive .mat-mdc-table .mdc-data-table__row:nth-child(even) .mdc-data-table__cell {
    background: #f1f2f3 !important;
}

.pending {
    color: #ef7701;
}

.approved,
.active {
    color: #10b73c;
}

.rejected,
.inactive {
    color: #e21c1c;
}

/* table css */
.mat-mdc-paginator-container {
    position: relative;
    min-height: inherit;
    padding: 15px 0 !important;
}

.mat-mdc-paginator-range-label {
    display: none;
}

.mat-mdc-paginator-container {
    justify-content: center !important;
    flex-direction: row-reverse;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size-select {
    width: 100px;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex {
    border: 1px solid #eaeaeb;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex .mdc-notched-outline {
    display: none;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex .mat-mdc-form-field-infix {
    padding: 0 !important;
    min-height: inherit;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select {
    padding: 0 !important;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-value {
    padding: 0 10px;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
}

.mat-mdc-paginator-container .mat-mdc-paginator-page-size-select .mat-mdc-form-field-flex .mat-mdc-form-field-infix .mat-mdc-select .mat-mdc-select-arrow-wrapper {
    height: 40px;
    width: 40px;
    justify-content: center;
    border-left: 1px solid #e9e9ea;
}

.mat-mdc-paginator-range-actions {
    border: 1px solid #e9e9ea;
    border-right: 0 !important;
}

.mat-mdc-paginator-range-actions .mat-mdc-tooltip-trigger {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    border-right: 1px solid #e9e9ea;
    border-radius: 0 !important;
}

.mat-mdc-paginator-range-actions .mat-mdc-tooltip-trigger .mat-mdc-button-persistent-ripple {
    display: none;
}

.g-bubble-container {
    display: flex;
}

.g-bubble-container .g-bubble {
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    padding: 0;
    justify-content: center;
    border-right: 1px solid #e9e9ea;
    border-radius: 0 !important;
    margin: 0 !important;
    font-weight: 700;
    font-size: 16px;
}

.g-bubble-container .g-bubble.g-bubble__active {
    background: #41c363;
}

.filter ul li.extra-filter {
    flex: auto;
    max-width: 100%;
}

.filter ul li.filter-mobile-icon {
    display: none;
}

.filter-icon {
    border: 1px solid #72777d;
    border-radius: 0;
    max-width: 30px;
    padding: 2px 5px;
    height: 30px;
    text-align: center;

}

.filter-icon:hover {
    border: 1px solid #41c363;
    background-color: #41c363;
}

.apply-filter-list {
    display: none;
}

.apply-filter-list ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
}

.apply-filter-list ul li {
    margin-right: 5px;
    margin-bottom: 5px;
}

.apply-filter-list ul li span {
    background: #41c363;
    padding: 5px 10px;
    border-radius: 17px;
    font-size: 12px;
    display: inline-flex;
    align-items: center;
}

.apply-filter-list ul li span a {
    margin-left: 5px;
    line-height: 0;
}

.apply-filter-list ul li span a img {
    max-width: 10px;
}

/* snackbar css */
.green-snackbar .mdc-snackbar__surface {
    background: #41c363 !important;
    border-radius: 0 !important;

}

.red-snackbar .mdc-snackbar__surface {
    background: #DC3545 !important;
    border-radius: 0 !important;
}

.green-snackbar .mdc-snackbar__surface .mat-mdc-snack-bar-label {
    font-family: Arial, Helvetica, sans-serif !important;
    color: #fff !important;
}

/* snackbar css */

/* switch css  */
.mat-mdc-slide-toggle .mdc-switch {
    width: 40px !important;
}

.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    height: 20px !important;
    border-radius: 20px !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::before {
    background: #ccc !important;
}

.mat-mdc-slide-toggle .mdc-switch:enabled .mdc-switch__track::after {
    background: #d1ffdd !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--unselected:enabled .mdc-switch__handle::after {
    background: #72777d !important;
}

.mat-mdc-slide-toggle .mdc-switch__icons,
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__ripple {
    display: none !important;
}

.mat-mdc-slide-toggle .mdc-switch.mdc-switch--selected:enabled .mdc-switch__handle::after {
    background: #41c363 !important;
}

/* switch css  */

/* pagination css */
.p-paginator .p-paginator-pages .p-paginator-page.p-highlight {
    background: #41c363 !important;
    border-color: #41c363 !important;
    color: #000 !important;
    font-weight: 600 !important;

}

.p-paginator .p-paginator-pages .p-paginator-page,
.p-paginator .p-paginator-prev,
.p-paginator .p-paginator-next,
.p-paginator .p-paginator-first,
.p-paginator .p-paginator-last {
    width: 36px !important;
    height: 36px !important;
    margin: 0 !important;
    border-right: 1px solid #b6bdbd !important;
    border-radius: 0 !important;
    font-family: Arial, Helvetica, sans-serif !important;
    border-top: 1px solid #b6bdbd !important;
    border-bottom: 1px solid #b6bdbd !important;
    box-shadow: none !important;
    color: #000 !important;
    font-weight: 700;
    margin-bottom: 5px !important;
}

.p-paginator .p-paginator-last {
    border-left: 1px solid #b6bdbd !important;
    margin-left: 10px !important;
    width: auto !important;
    padding: 5px 12px;
}

.p-paginator .p-paginator-first {
    margin-right: 10px !important;
    border-left: 1px solid #b6bdbd !important;
    width: auto !important;
    padding: 5px 12px;
}

.p-paginator .p-paginator-prev {
    border-left: 1px solid #b6bdbd !important;
}

.pi-angle-double-right:before {
    content: "Last" !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.pi-angle-double-left:before {
    content: "First" !important;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 500;
    font-size: 16px;
}

.p-paginator .p-dropdown {
    border-radius: 0;
    color: #000;
    border: 1px solid #b6bdbd;
    margin-left: 0px !important;
    margin-bottom: 5px !important;
}

.p-paginator p-dropdown {
    display: flex;
    align-items: center;
    margin-left: 15px !important;
    justify-content: center;
}

.p-paginator p-dropdown:before {
    content: 'Results Per Page :';
    margin-right: 10px;
    font-weight: 700;
    color: #4c4c4c;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
}

.p-paginator .p-dropdown .p-dropdown-label {
    min-width: 54px;
    width: auto;
    text-align: center;
    border-right: 1px solid #b6bdbd;
    border-radius: 0 !important;
    font-weight: bold;
    padding-left: 0;
    padding-top: 5px;
    padding-bottom: 5px;
}

.p-dropdown-panel .p-dropdown-items {
    border: 1px solid #72777d !important;
    padding: 0 !important;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item {
    padding: 4px 10px 5px !important;
    font-size: 14px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-item.p-highlight {
    background: #f1f2f3 !important;
}

.p-dropdown:not(.p-disabled) {
    box-shadow: none !important;
}

.p-dropdown:not(.p-disabled).p-focus {
    border: 1px solid #72777d !important;
}

.p-dropdown:not(.p-disabled):hover {
    border: 1px solid #72777d !important;
    box-shadow: none !important;
}

.p-element .p-paginator {
    padding: 10px 0 0;
}

.mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base {
    height: 32px;
    padding: 0 5px;
    width: 32px;
    background: url(../images/icon-date-picker.svg) no-repeat center center;
    background-size: 16px;
    opacity: 0.5;
}

.mat-datepicker-toggle .mat-mdc-icon-button.mat-mdc-button-base svg {
    display: none;
}

.mat-calendar-body-selected {
    background-color: #41c363 !important;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
    color: #41c363 !important;
}

/* DAILOG CSS */
.mdc-dialog__title {
    padding: 10px 0 !important;
}

.mdc-dialog__title:before {
    content: none !important;
}

.mdc-dialog__title h2 {
    margin: 0;
    font-size: 24px;
    font-weight: bold;
    text-align: center;
    color: #000;
    text-transform: capitalize;
}

.mat-mdc-dialog-content {
    max-height: calc(100vh - 130px) !important;
    overflow: visible;
    padding: 20px;
    min-width: 600px;
}

.mat-mdc-dialog-content .table-responsive {
    max-height: 450px;
}

.mat-mdc-dialog-actions {
    background: #F1F2F3;
    padding: 15px 0;
    text-align: center;
}

.ngx-mat-timepicker-dialog .mat-mdc-dialog-content{
    min-width: inherit;
}
.cdk-global-scrollblock{
    left: 0 !important;
    top: 0 !important;
}
.timepicker .mat-toolbar.mat-primary,
.timepicker  .mat-mdc-mini-fab.mat-primary{
    background: #41c363;
}
.ngx-mat-timepicker-dialog .mat-mdc-button:not(:disabled){
    background: #41c363;
    color: #000;
    margin-left: 10px;
    font-family: Arial, Helvetica, sans-serif;
    font-weight: 700;
    font-size: 14px;
    border-radius: 0;
}

/* DAILOG CSS */
/* checkbox design css  */
.mat-mdc-checkbox .mdc-checkbox {
    padding: 0 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mat-mdc-checkbox-touch-target {
    display: none !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control {
    width: 18px !important;
    height: 18px !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
    top: 0 !important;
    left: 0 !important;
    border: 1px solid !important;
    border-radius: 0 !important;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background {
    border-color: #41c363 !important;
    background-color: #41c363 !important;
}

/* checkbox design css  */
.btn-large {
    min-width: 120px;
}

.mat-mdc-form-field-error {
    font-size: 14px;
    color: #dd0707;
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background {
    padding: 3px;
    background-color: #41c363 !important;
    border-color: #41c363 !important;
}

.mat-mdc-dialog-container .mdc-dialog__content {
    color: #000 !important;
    font-family: Arial, Helvetica, sans-serif !important;
}

.mat-column-select {
    width: 50px;
}

.loader {
    background-color: rgba(255, 255, 255, 0.9);
    position: fixed;
    height: 100%;
    z-index: 99999;
    left: 0;
    right: 0;
    width: 100%;
    bottom: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.main-page h3 {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 0;
}

.main-page p {
    font-size: 14px;
    line-height: 1.3;
    margin: 0;
}

.notification-title-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
}

.view-comments {
    width: 33.33%;
    border: 1px solid #72777d;
    padding: 15px;
    border-radius: 5px;
    margin-top: 15px;
    word-wrap: break-word;
}
.view-comments.child-comment{
    margin-left: 30px;
}
.view-comments h3 {
    margin-bottom: 10px;
}

.p-dropdown-panel .p-dropdown-items .p-dropdown-empty-message{
    font-size: 14px;
}
.phone-number{
    display: flex;
}
.phone-number .country-type{
    max-width: 45px;
    margin-right: 10px;
}
ngx-treeview .row-filter{
    margin-bottom: 0 !important;
}
ngx-treeview .row-filter .form-control{
    font-size: 14px;
    border: 1px solid #72777d !important;
}
ngx-treeview .row-filter .form-control::placeholder{
    color: #6c757d;
}
ngx-treeview .row-all{
    display: none !important;
}
ngx-treeview  .treeview-container{
    border-bottom: 1px solid #72777d !important;
    border-radius: 5px;
    padding: 5px 10px;
}
.treeview-container .treeview-item .checkbox-label {
    position: relative;
    padding-left: 20px;
    font-size: 14px;display: inline-flex;
}
.treeview-container .treeview-item .checkbox-label:after{
    content: '';
    width: 16px;
    height: 16px;
    background: url(../images/checkbox-bg.png) no-repeat center center;
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
}
.treeview-container .treeview-item input:checked ~  .checkbox-label:after {
    background: url(../images/checkbox-checked.png) no-repeat center center;
}
.treeview-container .treeview-item input[type="checkbox"]{
    opacity: 0;
    width: 16px;
    height: 16px;
    position: absolute;
    z-index: 999;
    top: 9px;
    cursor: pointer;
}
.treeview-item{
    position: relative;
    padding: 4px 0 4px;
}
.treeview-item i{
    font-size: 26px;
    width: 15px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    line-height: 1;
    position: relative;
    opacity: 0;
}
.treeview-item i.toggle-active{
    opacity: 1;
}
.angular-editor-textarea:focus, .angular-editor-textarea:focus-visible,.treeview-header .form-control:focus, .treeview-header .form-control:focus-visible {
    border: 1px solid #41c363 !important;
    outline: none !important;
}
.treeview-text{
    font-size: 14px;
    padding-left: 15px !important; 
}
.mat-mdc-tab-header{
    margin-left: -20px;
    margin-right: -20px;
}
.mat-mdc-tab-header .mat-mdc-tab-labels{
    border-bottom: 3px solid #000;
    padding: 0 20px;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab{
    height: 38px;
    flex-grow: 0;
    background: #fff;
    border: 1px solid #72777D;
    margin-right: 5px;
    border-radius: 4px 4px 0 0;
    padding: 0 30px;
    border-bottom: 0;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active{
    border: 1px solid #000;
    background: #000;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab.mdc-tab-indicator--active .mdc-tab__text-label{
    color: #fff;
}
.mat-mdc-tab-header .mat-mdc-tab-labels .mat-mdc-tab.mdc-tab .mdc-tab__text-label{
    color: #000;
    text-transform: uppercase;
    transition-delay: inherit !important;
    transition: none !important;
}
.mat-mdc-tab-header .mdc-tab-indicator{
    display: none !important;
}
.mat-mdc-tab-body-wrapper{
    margin-left: -20px;
    margin-right: -20px;
}
.mat-mdc-tab-body-wrapper .mat-mdc-tab-body-content{
    padding: 20px;
}
.widget-box{
    background: #CCE5FF;
    border: 1px solid #B8DAFF;
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    height: 100%;
}
.widget-box span{
    color: #004085;
    font-size: 42px;
}
.main-page .widget-box h3{
    color: #004085;
    font-weight: normal;
    font-size: 20px;
}
.widget-box.yellow-bg{
    background: #FFF3CD;
    border: 1px solid #FFEEBA;
}
.widget-box.yellow-bg span{
    color: #856404;
}
.widget-box.yellow-bg h3{
    color: #856404;
}
.widget-box.gray-bg{
    background: #E7E8EA;
    border: 1px solid #DDDFE2;
}
.widget-box.gray-bg span{
    color: #464A4E;
}
.widget-box.gray-bg h3{
    color: #464A4E;
}
.mat-mdc-checkbox{
    margin-right: 15px;
    padding-bottom: 5px;
}
.mat-mdc-checkbox label{
    margin-bottom: -1px;
}
.checkbox-content {
    border: 1px solid #b8bbbd;
    overflow: hidden;
}
.checkbox-content .col-4 label{
    display: block;
    background: #f1f2f3;
    padding: 10px;
    border-right: 1px solid #b8bbbd;
}
.checkbox-content .col-2{
    padding-top: 8px;
}
.checkbox-content .col-2 .mat-mdc-checkbox{
    margin: 0;
    padding: 0;
}
.checkbox-content  > .row > .col-4, .checkbox-content  > .row > .col-2{
    border-bottom: 1px solid #b8bbbd;
}
.checkbox-content  > .row:last-child > .col-4, .checkbox-content  > .row:last-child > .col-2 {
    border-bottom: 0;
}
.set-permission-box .mat-mdc-checkbox{
    margin-right: 0 !important;
}
ngx-dropdown-treeview .dropdown-menu {
    left: 0;
    right: 0;
    padding: 0;
    border: 1px solid #72777d !important;
    overflow: hidden;
    bottom: 36px;
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
ngx-dropdown-treeview .dropdown-menu .dropdown-container{
    padding: 0 !important;
}
.treeview-header{
    padding: 5px 7px;
    border-bottom: 1px solid #72777d !important;
    background: #f9f8fa;
}
ngx-dropdown-treeview .dropdown-divider{
    display: none !important;

}
ngx-dropdown-treeview .dropdown-toggle, ngx-dropdown-treeview .dropdown-toggle:hover{
    font-size: 14px;
    background: transparent url(../images/select-arrow.png) no-repeat right 7px center  !important;
    color: #6c757d;
}
ngx-dropdown-treeview .dropdown-toggle:after{
    content: none;
}
.timepicker-dial__control{
    font-size: 42px;
}
.timepicker-period__btn{
    font-size: 16px;
}
.view-notification .angular-editor-textarea{
    min-height: inherit !important;
    max-height: inherit !important;
    padding: 0 !important;
    border: 0 !important;
    margin-bottom: 15px ;
}
.view-notification .angular-editor-textarea i{
    cursor: default;
}
.lnk-color{
    color: #41c363 !important;
    font-size: 14px;
 }
 .accept-cookies{
    z-index: 9999;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #f3f3f3;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.2);
    padding: 10px;
    max-width: 1024px;
    margin: auto;
    border-radius: 4px 4px 0 0;
 }
 .accept-cookies  .cookies-title{
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 5px;
 }
 .cookies-content{
    display: flex;
    align-items: center;
 }
 .cookies-content .cookies-content-left{
    font-size: 14px;
    flex: auto;
 }
 .cookies-content-right{
    display: flex;
 }
 
@media (max-width:1599px){
.side-nav{width: 230px;}
.right-side-content{    margin-left: 230px;}
.top-logo{    height: 60px;    padding: 10px 15px;}
.left-menu ul li a{padding: 15px;}
.left-menu ul li a img {
    max-width: 20px;
    min-width: 20px;
    max-height: 20px;
}
.left-menu ul li a span{    font-size: 14px;}
.top-logo a img {
    max-width: 130px;
}
.top-logo a img.small-logo{    max-width: 24px;}
.close-menu .side-nav{    width: 50px;}
.close-menu .right-side-content{    margin-left: 50px;}
header{    height: 60px;     padding: 0 20px;}
.left-header img{    max-width: 24px;}
.close-menu .top-logo {
    padding: 10px;
}
.close-menu .left-menu ul li a:hover span{    left: 48px; font-size: 13px;}
.filter ul li{
    flex: 0 0 180px;
    max-width: 180px;
}
.main-content .title-header h2{
    font-size: 20px;
}
.btn-avnet, .btn-avnet-outline, .btn-avnet .btn-avnet-red{
    padding: 5px 15px;
}
.filter .btn-avnet-outline{
    min-width: inherit; 
}
.table-responsive .mat-mdc-table .mdc-data-table__header-row .mdc-data-table__header-cel,
.mat-sort-header-content{
    font-size: 12px;
}
.table-responsive .mat-mdc-table .mdc-data-table__row .mdc-data-table__cell{
    font-size: 12px;
}
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle{
    width: 16px !important;
    height: 16px !important;
}
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__track{
    height: 16px !important;
}
.mat-mdc-slide-toggle .mdc-switch{
    width: 32px !important;
}
.mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track{
    width: calc(100% - 16px) !important;
}
.filter ul{
    margin-left: -10px;
    margin-right: -10px;
}
.filter ul li{
    padding-left: 10px;
    padding-right: 10px;
}
.table-responsive .mat-mdc-table .mdc-data-table__row{
    height: 45px;
}
.mat-mdc-dialog-content .table-responsive{
    max-height: 290px;
}
.main-content .title-header{
    flex-flow: wrap;
}
}

@media (max-width:1199px) {
    .side-nav {
        left: -230px;
        transition: 0.5s all;
    }

    .right-side-content {
        margin-left: 0 !important;
    }

    header {
        background: #000;
    }

    .left-header img {
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(162deg) brightness(106%) contrast(104%);
    }

    .right-header .dropdown-toggle .profile-username,
    .right-header .dropdown-toggle .welcome {
        display: none;
    }

    .mobile-user-icon {
        display: inline-block;
    }

    .mobile-user-icon img {
        width: 30px;
        filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(162deg) brightness(106%) contrast(104%);
    }

    .right-header .dropdown-toggle:after {
        color: #fff;
    }

    .right-header .dropdown-menu {
        padding: 0;
    }

    .right-header .dropdown-menu .mobile-username {
        display: block;
        background: #f1f2f3;
        padding: 5px 15px;
    }

    .right-header .dropdown-menu .mobile-username .welcome {
        display: block;
        font-size: 12px;
    }

    .right-header .dropdown-menu .mobile-username .profile-username {
        font-weight: 700;
        font-size: 14px;
    }

    .mobile-logo-white {
        display: block;
    }

    .mobile-logo-white img {
        width: 120px;
    }

    .close-menu .side-nav {
        left: 0 !important;
        top: 60px;
        width: 230px !important;
        transition: 0.5s all;
    }

    .close-menu .side-nav .top-logo {
        display: none;
    }

    .close-menu .left-menu ul li a span {
        display: block;
    }

    .close-menu .left-menu ul li a span:before {
        content: none !important;
    }

    .close-menu .left-menu ul li a:hover span {
        padding: 0 !important;
        background: transparent;
        position: static;
        margin-left: 15px;
        font-size: 14px;
    }

    .filter ul li.filter-mobile-icon {
        display: block;
        text-align: right;
        flex: auto;
        max-width: inherit;
        margin-bottom: 15px;
    }

    .filter>ul {
        justify-content: space-between;
        position: relative;
    }

    .filter ul li.extra-filter {
        display: none;
        position: absolute;
        right: 15px;
        background: #fff;
        box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.09);
        padding: 10px 0;
        max-width: 200px;
        z-index: 9;
        top: calc(100% - 10px);
    }

    .filter ul li.extra-filter.open {
        display: block;
    }

    .filter ul li.extra-filter ul {
        margin: 0;
        width: 100%;
    }

    .filter ul li.extra-filter ul li {
        max-width: 100%;
        flex: 0 0 100%;
    }

    .filter .btn-avnet-outline,
    .filter .btn-avnet {
        width: 100%;
    }

    .filter ul li.extra-filter:before {
        content: '';
        border-bottom: 8px solid #fff;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        position: absolute;
        top: -6px;
        right: 9px;
        box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.09);
    }

    .apply-filter-list {
        display: block;
    }

    .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
        width: 16px !important;
        height: 16px !important;
    }

    .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
        height: 16px !important;
    }
    .main-content .title-header h2{
        font-size: 18px;
    }
}

@media (max-width:767px) {
    .login-bg .login-box .login-form {
        max-width: 300px;
        padding: 15px 25px 30px;
    }
    .login-bg .login-box .login-form.registration-form{
        max-width: 90%;
        margin: auto;
    }

    .filter ul li.search-group {
        flex: 0 0 220px;
        max-width: 220px;
    }

    .filter ul li.filter-mobile-icon {
        flex: auto;
        max-width: inherit;
    }

    .main-content {
        padding: 15px 10px 0;
    }

    .p-inputwrapper-filled {
        flex: 0 0 100%;
        text-align: center;
        padding-top: 10px;
    }
    .mat-mdc-dialog-content{
        min-width: 100%;
    }
    .widget-box{
        margin-bottom: 15px;
        height: auto;
    }
    .checkbox-content .row{
        flex-flow: nowrap;
    }
    .checkbox-content .col-4 label{
        height: 100%;
    }
    .checkbox-content > .row > .col-4{
        min-width: 220px;
    }
    .checkbox-content > .row > .col-2{
        min-width: 175px;
    }
    .checkbox-content{
        overflow: auto;
    }
    .view-comments{
        width: 100%;
    }
    .view-comments.child-comment{
        width: calc(100% - 30px);
    }
    .comment-title{
        min-width: 300px;
    }
    .cookies-content{
        flex-flow: wrap;
     }
     .cookies-content .cookies-content-left{
        flex: 0 0 100%;
        padding-bottom: 5px;
     }
}
.angular-editor-textarea *{
    word-break: break-word;
}
.view-notification .angular-editor-textarea{
    resize: none !important;
}